<template>
  <div class="shade-bg" v-show="isOpen">
    <div class="img">
      <img src="../assets/icon/icon_loading_w.png" />
      <p>{{msg}}</p>
    </div>
  </div>
</template>

<script>
import * as qiniu from 'qiniu-js'

export default {
  name: 'qiniu-upload',
  props: {},
  data () {
    return {
      // 状态
      isOpen: false,
      msg: '上传中...',
      // form upload config
      subscription: {},
      token: '',
      domain: '',
      config: {
        useCdnDomain: false,
        region: null
      },
      // 上传数据
      files: [],
      total: 0,
      current: 0,
      // 返回值
      result: [],
      fn: []
    }
  },
  methods: {
    getQiniuToken: function () {
      this.$post('qiniu/getUpToken').then((res) => {
        if (res.code === 1) {
          this.token = res.data.uptoken
          console.log(this.token)
        } else {
          console.log(res.msg)
        }
      }).catch((res) => {
        console.log(res)
      })
    },
    qiniuUpload: function (files, fun) {
      this.result = []
      this.isOpen = true
      this.files = files
      this.total = files.length
      this.fn = fun
      this.qiniuloop()
    },
    qiniuloop: function () {
      if (this.current < this.total) {
         //debugger
         //console.log(this.files[this.current])

        this.formDataUpload(this.files[this.current])
      } else {
        this.callBack()
        this.current = 0;
      }
    },
    formDataUpload: function (file) {
      let putExtra = {
        fname: file.name,
        params: {},
        mimeType: null
      }
      //console.log(this.token);
      let _rand = Math.floor(Math.random()*1.2);
      let name = 'picNames'+ _rand;
      let observable = qiniu.upload(file, file.name, this.token, putExtra, this.config)
      this.subscription = observable.subscribe((res) => {
        console.log('percent', res.total.percent)
      }, (err) => {
        console.log('err', err)
      }, (res) => {
        console.log('res', res)
        this.result.push(res)
        this.current++
        this.qiniuloop()
      })
    },
    qiniuCancel: function () {
      // 上传取消
      this.subscription.unsubscribe()
    },
    callBack: function () {
      this.fn(this.result)
      this.isOpen = false
    }
  },
  watch: {
  },
  created () {
    this.getQiniuToken()
  }
}
</script>

<style lang="less" scoped>
.shade-bg{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 200;
  background-color: rgba(0,0,0,0.72);
}
@keyframes loading
{
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.shade-bg .img{
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  z-index: 1;
  background-color: transparent;
}
.shade-bg img{
  display: block;
  width: 1.066666rem;
  margin: auto;
  animation: loading 2s linear infinite;
}
.shade-bg p{
  color: #FFFFFF;
  font-size: 0.373333rem;
  margin-top: 0.266666rem;
  text-align: center;
}
</style>